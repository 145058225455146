import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';

// constants
import routesConfig from 'constants/routesConfig.json';

// components, styles, assets
import ChatBubble from 'components/pageECoach/ChatBubble';
import ClickableInput from 'components/pageECoach/ClickableInput';
import ConfirmDialog from 'components/_pageLayout/ConfirmDialog';
import * as History from 'components/pageECoach/_styles/History.style';
import Loader from 'components/pageECoach/Loader';
import { General } from 'untitledui-js';
import { Stack } from '@mui/material';

// hooks
import useApi from 'hooks/useApi';
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import colours from 'constants/colours';
import * as Cards from 'components/pageECoach/_styles/Cards.style';
import * as Text from 'components/_styles/Text.style';

export default function ECoachSessionList({
  activeSession,
  handleNewChat,
  navigate,
  resetChatGuideData,
  setActivePage,
}) {
  const { data: sessionList, callApi: getSessionList } = useApi();
  const { data: updatedSessions, callApi: updateSession } = useApi();
  const { t } = useTranslation('pageEcoach');
  const lang = useUserLanguage();
  const posthog = usePostHog();

  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const [sessionId, setSessionId] = useState();
  const handleConfirmDialogClose = () => setIsOpenConfirmDialog(false);
  const handleConfirmDialogOpen = (e, pastSession) => {
    e.stopPropagation();
    setSessionId(pastSession);
    setIsOpenConfirmDialog(true);
  };

  useEffect(() => {
    getSessionList(routesConfig.ECOACH.SESSIONS);
  }, [getSessionList]);

  useEffect(() => {
    if (updatedSessions.status === 'success')
      getSessionList(routesConfig.ECOACH.SESSIONS);
  }, [getSessionList, updatedSessions]);

  const hideSession = () => {
    updateSession(`${routesConfig.ECOACH.SESSIONS}${sessionId}/hide`, 'put');
    posthog?.capture('user_removed_session');
    if (sessionId === activeSession) navigate(null);
    handleConfirmDialogClose();
  };

  const clickSession = (pastSessionId) => {
    resetChatGuideData();
    setActivePage('chat');
    posthog?.capture('user_clicked_past_session');
    navigate(pastSessionId);
  };

  const getContent = (session) => {
    if (session?.description)
      return <Text.P2Semibold>{session.description}</Text.P2Semibold>;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        {session.with_who && (
          <Text.P2>
            <b style={{ fontWeight: '500' }}>
              {t('convoPrep.Who would you like to have a conversation with?')}{' '}
            </b>
            {session.with_who}
          </Text.P2>
        )}
        {session.goal && (
          <Text.P2>
            <b style={{ fontWeight: '500' }}>
              {t('convoPrep.What is your main goal going into this meeting?')}{' '}
            </b>
            {session.goal}
          </Text.P2>
        )}
        {session.worried_by && (
          <Text.P2>
            <b style={{ fontWeight: '500' }}>
              {t(
                'convoPrep.Do you have any specific concerns or worries about your meeting?'
              )}{' '}
            </b>
            {session.worried_by}
          </Text.P2>
        )}
      </div>
    );
  };

  const confirmDialog = (
    <ConfirmDialog
      cancelLabel={t('No')}
      successLabel={t('Yes')}
      successCallback={() => hideSession(sessionId)}
      contentText={t('Are you sure you want to delete this session?')}
      handleClose={handleConfirmDialogClose}
      isOpen={true}
    />
  );

  const pastSessions =
    sessionList.status === 'success' ? sessionList.response : [];

  if (sessionList.status === 'loading') return <Loader inline />;
  if (pastSessions?.length === 0)
    return (
      <ChatBubble>
        <Text.P2Semibold>
          {t("You don't have any previous sessions yet.")}
        </Text.P2Semibold>
        <ClickableInput
          inputAction={handleNewChat}
          inputText={t('Get started with a new chat')}
          inputType="primary"
        />
      </ChatBubble>
    );

  return (
    <Stack spacing={4}>
      <Text.SubheadingSemibold>
        {t('Previous sessions')}
      </Text.SubheadingSemibold>
      <Cards.MultiCardSection>
        {pastSessions.map((pastSession) => (
          <History.Card
            key={pastSession.id}
            onClick={() => clickSession(pastSession.id)}
          >
            <Stack spacing={1}>
              <Stack justifyContent={'space-between'} direction={'row'}>
                <History.Date>
                  {dayjs().locale(lang).to(dayjs(pastSession.created_at))}
                </History.Date>
                <History.DeleteIcon>
                  <General.Trash01
                    onClick={(e) => handleConfirmDialogOpen(e, pastSession.id)}
                    stroke={colours.neutralCool[600]}
                    size="15"
                    whileHover={colours.primary[500]}
                  />
                </History.DeleteIcon>
              </Stack>
              {getContent(pastSession)}
            </Stack>
            <History.BottomBlur />
          </History.Card>
        ))}
      </Cards.MultiCardSection>
      {isOpenConfirmDialog && confirmDialog}
    </Stack>
  );
}

ECoachSessionList.propTypes = {
  activeSession: PropTypes.string,
  handleNewChat: PropTypes.func,
  navigate: PropTypes.func,
  resetChatGuideData: PropTypes.func,
  setActivePage: PropTypes.func,
};
